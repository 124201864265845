"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _Pagination = _interopRequireDefault(require("@/components/Pagination"));

var _errorParse = require("@/utils/error-parse");

var _cashExpense = require("@/api/cashExpense");

var _elementUi = require("element-ui");

var _store = require("@/api/store");

var _i18n = _interopRequireDefault(require("@/i18n"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    Pagination: _Pagination.default
  },

  /* filters: {
    statusFilter(status) {
      const statusMap = {
        published: 'success',
        draft: 'gray',
        deleted: 'danger'
      }
      return statusMap[status]
    }
  }, */
  data() {
    return {
      uploadDomain: process.env.VUE_APP_BASE_API,
      categories: [{
        id: '',
        name: _i18n.default.t('CashExpense.All')
      }, {
        id: 'Stationary',
        name: _i18n.default.t('CashExpense.Stationary')
      }, {
        id: 'Food',
        name: _i18n.default.t('CashExpense.Food')
      }, {
        id: 'Miscellaneous',
        name: _i18n.default.t('CashExpense.Miscellaneous')
      }, {
        id: 'Laundry',
        name: _i18n.default.t('CashExpense.Laundry')
      }, // {id : "InkBox", name : i18n.t('CashExpense.InkBox')},
      {
        id: 'Logistic',
        name: _i18n.default.t('CashExpense.Logistic')
      }, {
        id: 'Cleaning',
        name: _i18n.default.t('CashExpense.Cleaning')
      }, {
        id: 'FoodIngredients',
        name: _i18n.default.t('CashExpense.FoodIngredients')
      }, {
        id: 'Bar',
        name: _i18n.default.t('CashExpense.Bar')
      }],
      stores: [{
        uuid: '',
        name: 'All Stores'
      }],
      list: null,
      listLoading: false,
      listQuery: {
        category: '',
        store_id: '',
        date_range: [],
        page: 1,
        limit: 10,
        listAll: true
      },
      total: 0
    };
  },

  async created() {
    (0, _store.getStores)().then(storeResponse => {
      const storeData = storeResponse.data;
      storeData.splice(0, 0, {
        uuid: '',
        name: 'All Stores'
      });
      this.stores = storeData;
    });
    const start = new Date();
    start.setDate(start.getDate() - 7);
    let dd = String(start.getDate()).padStart(2, '0');
    let mm = String(start.getMonth() + 1).padStart(2, '0'); // January is 0!

    let yyyy = start.getFullYear();
    const format1 = yyyy + '-' + mm + '-' + dd;
    const next = new Date();
    dd = String(next.getDate()).padStart(2, '0');
    mm = String(next.getMonth() + 1).padStart(2, '0'); // January is 0!

    yyyy = next.getFullYear();
    const format2 = yyyy + '-' + mm + '-' + dd;
    this.listQuery.date_range = [format1, format2];

    if (this.$route.params.date_range) {
      try {
        const date_range = this.$route.params.date_range.split(',');
        this.listQuery.date_range = [date_range[0], date_range[1]];
      } catch (e) {
        console.error(e);
      }
    }

    if (this.$route.params.category) {
      this.listQuery.category = this.$route.params.category;
    }

    this.fetchData();
  },

  methods: {
    async fetchData() {
      this.listLoading = true;
      (0, _cashExpense.getList)(this.listQuery).then(response => {
        this.list = response.data;
        this.total = response.meta.total;
      }).finally(() => {
        this.listLoading = false;
      });
    },

    editCashExpense(id) {
      this.$router.push(`/cash-expense/edit/${id}`);
    },

    addCashExpense() {
      this.$router.push(`/cash-expense/add`);
    },

    deleteCashExpense(id) {
      return _elementUi.MessageBox.confirm('Confirm to delete record?', 'Confirm', {
        confirmButtonText: 'Delete',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(() => (0, _cashExpense.deleteCashExpense)(id).then(() => {
        (0, _elementUi.Message)({
          message: 'Record deleted',
          type: 'success',
          duration: 5 * 1000
        });
        return this.fetchData();
      }).catch(err => err.status === 404 ? Promise.resolve('already deleted') : (0, _elementUi.Message)({
        dangerouslyUseHTMLString: true,
        message: (0, _errorParse.parseError)(err) || 'Delete failed, please retry.',
        type: 'error',
        duration: 5 * 1000
      }))).catch(err => err === 'cancel' ? Promise.resolve('cancelled') : Promise.reject(err));
    },

    getTotal(param) {
      const {
        columns,
        data
      } = param;
      const sums = [];

      if (data != null) {
        columns.forEach((column, index) => {
          const values = data.map(item => Number(item[column.property]));

          if (column.property === 'price') {
            sums[index] = _i18n.default.t('Order.Total') + ': $' + values.reduce((prev, curr) => {
              const value = Number(curr);

              if (!isNaN(value)) {
                return prev + curr;
              } else {
                return prev;
              }
            }, 0);
          } else {
            sums[index] = '';
          }
        });
      }

      return sums;
    },

    translateCategory(key) {
      let category_name = '';

      for (let i = 0; i < this.categories.length; i++) {
        const category = this.categories[i];

        if (category.id === key) {
          category_name = category.name;
          break;
        }
      }

      return category_name;
    }

  }
};
exports.default = _default;