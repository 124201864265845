var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c("div", { staticClass: "action-container" }, [
        _c(
          "div",
          { staticClass: "filter" },
          [
            _c(
              "div",
              { staticClass: "left" },
              [
                _c(
                  "el-select",
                  {
                    attrs: { placeholder: "Category" },
                    model: {
                      value: _vm.listQuery.category,
                      callback: function ($$v) {
                        _vm.$set(_vm.listQuery, "category", $$v)
                      },
                      expression: "listQuery.category",
                    },
                  },
                  _vm._l(_vm.categories, function (category) {
                    return _c("el-option", {
                      key: category.name,
                      attrs: { label: category.name, value: category.id },
                    })
                  }),
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-select",
                  {
                    attrs: { placeholder: "Store" },
                    model: {
                      value: _vm.listQuery.store_id,
                      callback: function ($$v) {
                        _vm.$set(_vm.listQuery, "store_id", $$v)
                      },
                      expression: "listQuery.store_id",
                    },
                  },
                  _vm._l(_vm.stores, function (store) {
                    return _c("el-option", {
                      key: store.uuid,
                      attrs: { label: store.name, value: store.uuid },
                    })
                  }),
                  1
                ),
                _vm._v(" "),
                _c("el-date-picker", {
                  attrs: {
                    type: "daterange",
                    "range-separator": "to",
                    "start-placeholder": "Start",
                    "end-placeholder": "End",
                    "value-format": "yyyy-MM-dd",
                  },
                  model: {
                    value: _vm.listQuery.date_range,
                    callback: function ($$v) {
                      _vm.$set(_vm.listQuery, "date_range", $$v)
                    },
                    expression: "listQuery.date_range",
                  },
                }),
                _vm._v(" "),
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary", icon: "el-icon-edit" },
                    on: { click: _vm.fetchData },
                  },
                  [_vm._v(_vm._s(_vm.$t("PurchaseRequests.Filter")))]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-button",
              {
                attrs: { type: "primary", icon: "el-icon-circle-plus" },
                on: { click: _vm.addCashExpense },
              },
              [_vm._v("Add Cash Expense")]
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          attrs: {
            data: _vm.list,
            "element-loading-text": "Loading",
            border: "",
            fit: "",
            "highlight-current-row": "",
            "show-summary": "",
            "summary-method": _vm.getTotal,
          },
        },
        [
          _c("el-table-column", {
            attrs: { align: "center", label: "ID", width: "95" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(scope.row.id.toString()) +
                        "\n      "
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: _vm.$t("PurchaseRequests.Store"), width: "150" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      "\n        " + _vm._s(scope.row.store.name) + "\n      "
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "Categoey", width: "150" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.translateCategory(scope.row.category)) +
                        "\n      "
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "Description" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      "\n        " + _vm._s(scope.row.description) + "\n      "
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "Price",
              prop: "price",
              align: "right",
              width: "200",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      "\n        $" + _vm._s(scope.row.price) + "\n      "
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "Date", width: "120", align: "right" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      "\n        " + _vm._s(scope.row.apply_date) + "\n      "
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "Attachment", align: "right" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "div",
                      _vm._l(scope.row.attachments, function (attachment, i) {
                        return _c("div", { key: i }, [
                          _c(
                            "a",
                            {
                              attrs: {
                                href: _vm.uploadDomain + "/" + attachment.path,
                                target: "_blank",
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("PurchaseRequests.Download"))
                              ),
                            ]
                          ),
                        ])
                      }),
                      0
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: _vm.$t("Order.Actions"), width: "200" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "warning", icon: "el-icon-edit" },
                        on: {
                          click: function ($event) {
                            return _vm.editCashExpense(scope.row.uuid)
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("Order.Edit")))]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "danger", icon: "el-icon-delete" },
                        on: {
                          click: function ($event) {
                            return _vm.deleteCashExpense(scope.row.uuid)
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("Order.Delete")))]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("pagination", {
        attrs: {
          total: _vm.total,
          page: _vm.listQuery.page,
          limit: _vm.listQuery.limit,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.listQuery, "page", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.listQuery, "limit", $event)
          },
          pagination: _vm.fetchData,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }