"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _auth = require("@/utils/auth");

var _errorParse = require("@/utils/error-parse");

var _elementUi = require("element-ui");

var _cashExpense = require("@/api/cashExpense");

var _store = require("@/api/store");

var _i18n = _interopRequireDefault(require("@/i18n"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// import { getInfo } from '@/api/user'
var _default = {
  data() {
    return {
      loading: false,
      rules: {
        description: {
          required: true,
          message: 'Description is required'
        },
        store_id: {
          required: true,
          message: 'Store is required'
        },
        price: {
          required: true,
          message: 'Price is required'
        },
        apply_date: {
          required: true,
          message: 'Date is required'
        }
      },
      fileList: [],
      uploadDomain: process.env.VUE_APP_BASE_API,
      uploadHeader: {
        Authorization: 'Bearer ' + (0, _auth.getToken)(),
        'X-Requested-With': 'XMLHttpRequest'
      },
      uploadData: {
        cash_expense_id: ''
      },
      stores: [],
      categories: [{
        id: 'Stationary',
        name: _i18n.default.t('CashExpense.Stationary')
      }, {
        id: 'Food',
        name: _i18n.default.t('CashExpense.Food')
      }, {
        id: 'Miscellaneous',
        name: _i18n.default.t('CashExpense.Miscellaneous')
      }, {
        id: 'Laundry',
        name: _i18n.default.t('CashExpense.Laundry')
      }, // {id : "InkBox", name : i18n.t('CashExpense.InkBox')},
      {
        id: 'Logistic',
        name: _i18n.default.t('CashExpense.Logistic')
      }, {
        id: 'Cleaning',
        name: _i18n.default.t('CashExpense.Cleaning')
      }, {
        id: 'FoodIngredient',
        name: _i18n.default.t('CashExpense.FoodIngredients')
      }, {
        id: 'Bar',
        name: _i18n.default.t('CashExpense.Bar')
      }],
      CashExpense: {
        id: 0,
        store_id: null,
        category: null,
        title: '',
        description: '',
        price: 0,
        remark: '',
        apply_date: '',
        attachmentArrayId: []
      }
    };
  },

  computed: {
    device() {
      return this.$store.state.app.device;
    }

  },

  async created() {
    (0, _store.getStores)().then(storeResponse => {
      this.stores = storeResponse.data;
    });
    const today = new Date();
    const dd = String(today.getDate()).padStart(2, '0');
    const mm = String(today.getMonth() + 1).padStart(2, '0'); // January is 0!

    const yyyy = today.getFullYear();
    this.CashExpense.apply_date = yyyy + '-' + mm + '-' + dd;

    if (this.$route.params.id) {
      this.fetchData();
    } // removeExpenseAttachment(-1);

  },

  methods: {
    fetchData() {
      this.loading = true;
      (0, _cashExpense.getCashExpense)(this.$route.params.id).then(response => {
        this.CashExpense = response.data;
        const store = response.data.store;
        this.CashExpense.store_id = store.uuid;
        this.fileList = [];
        this.CashExpense.attachmentArrayId = [];
        this.CashExpense.attachments.forEach(attachment => {
          let path = attachment.path; // eslint-disable-next-line no-empty

          try {
            path = path.split('/')[1];
          } catch (e) {}

          this.fileList.push({
            id: attachment.id,
            name: path,
            url: attachment.url
          });
        });
        this.uploadData.cash_expense_id = this.CashExpense.id;
      }).catch(error => console.error(error)).finally(() => {
        this.loading = false;
      });
    },

    submitExpense() {
      this.$refs.form.validate(valid => {
        if (!valid) {
          return false;
        }

        const price = this.CashExpense.price;

        if (isNaN(price)) {
          this.$message.error('Price must be a number.');
          return false;
        }

        this.loading = true;

        if (this.$route.params.id) {
          (0, _cashExpense.editCashExpense)(this.CashExpense).then(() => {
            (0, _elementUi.Message)({
              message: 'Cash Expense updated.',
              type: 'success',
              duration: 5 * 1000
            });
            this.$router.push(`/cash-expense`);
          }).catch(err => {
            (0, _elementUi.Message)({
              dangerouslyUseHTMLString: true,
              message: (0, _errorParse.parseError)(err) || 'Update failed, please retry.',
              type: 'error',
              duration: 5 * 1000
            }); // console.log(err)

            this.loading = false;
          });
        } else {
          (0, _cashExpense.createCashExpense)(this.CashExpense).then(() => {
            (0, _elementUi.Message)({
              message: 'Cash Expense created.',
              type: 'success',
              duration: 5 * 1000
            });
            this.$router.push(`/cash-expense`);
          }).catch(err => {
            (0, _elementUi.Message)({
              dangerouslyUseHTMLString: true,
              message: (0, _errorParse.parseError)(err) || 'Create failed, please retry.',
              type: 'error',
              duration: 5 * 1000
            });
            this.loading = false;
          });
        }
      });
    },

    handleRemove(file, fileList) {
      // console.log(file, fileList);
      if (file.id !== undefined) {
        (0, _cashExpense.removeExpenseAttachment)(file.id);
      } else if (file.response[0].id !== undefined) {
        (0, _cashExpense.removeExpenseAttachment)(file.response[0].id);
      } else {
        console.error(file);
      }
    },

    handlePreview(file) {
      window.open(file.url);
    },

    handleExceed(files, fileList) {
      this.$message.warning('Maximum upload 3 files.');
    },

    beforeRemove(file, fileList) {
      return this.$confirm(`Do you confirm remove ${file.name}？`);
    },

    handleSuccess(response, file, fileList) {
      this.CashExpense.attachmentArrayId.push(response[0].id);

      if (this.$route.params.id) {
        this.fetchData(true);
      }
    }

  }
};
exports.default = _default;