var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "app-container",
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            model: _vm.CashExpense,
            rules: _vm.rules,
            "label-width": "auto",
            "label-position": _vm.device === "mobile" ? "top" : "left",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "ID" } },
            [
              _c("el-input", {
                attrs: { disabled: "" },
                model: {
                  value: _vm.CashExpense.id,
                  callback: function ($$v) {
                    _vm.$set(_vm.CashExpense, "id", $$v)
                  },
                  expression: "CashExpense.id",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$t("PurchaseRequests.Store"),
                prop: "store_id",
              },
            },
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "Store" },
                  model: {
                    value: _vm.CashExpense.store_id,
                    callback: function ($$v) {
                      _vm.$set(_vm.CashExpense, "store_id", $$v)
                    },
                    expression: "CashExpense.store_id",
                  },
                },
                _vm._l(_vm.stores, function (store) {
                  return _c("el-option", {
                    key: store.id,
                    attrs: { label: store.name, value: store.uuid },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "Category", prop: "category" } },
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "Category" },
                  model: {
                    value: _vm.CashExpense.category,
                    callback: function ($$v) {
                      _vm.$set(_vm.CashExpense, "category", $$v)
                    },
                    expression: "CashExpense.category",
                  },
                },
                _vm._l(_vm.categories, function (category) {
                  return _c("el-option", {
                    key: category.id,
                    attrs: { label: category.name, value: category.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "Description", prop: "description" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.CashExpense.description,
                  callback: function ($$v) {
                    _vm.$set(_vm.CashExpense, "description", $$v)
                  },
                  expression: "CashExpense.description",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "Price", prop: "price" } },
            [
              _c("el-input-number", {
                model: {
                  value: _vm.CashExpense.price,
                  callback: function ($$v) {
                    _vm.$set(_vm.CashExpense, "price", $$v)
                  },
                  expression: "CashExpense.price",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "Reason", prop: "remark" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.CashExpense.remark,
                  callback: function ($$v) {
                    _vm.$set(_vm.CashExpense, "remark", $$v)
                  },
                  expression: "CashExpense.remark",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "Date", prop: "apply_date" } },
            [
              _c("el-date-picker", {
                attrs: {
                  type: "date",
                  placeholder: "Please select date",
                  "value-format": "yyyy-MM-dd",
                },
                model: {
                  value: _vm.CashExpense.apply_date,
                  callback: function ($$v) {
                    _vm.$set(_vm.CashExpense, "apply_date", $$v)
                  },
                  expression: "CashExpense.apply_date",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "Attachment", prop: "attachment" } },
            [
              _c(
                "el-upload",
                {
                  staticClass: "upload-demo",
                  attrs: {
                    name: "attachment[]",
                    action:
                      _vm.uploadDomain +
                      "/api/v1/cash-expense-attachment/upload",
                    "on-preview": _vm.handlePreview,
                    "on-remove": _vm.handleRemove,
                    "before-remove": _vm.beforeRemove,
                    "on-success": _vm.handleSuccess,
                    multiple: "",
                    drag: "",
                    limit: 3,
                    "on-exceed": _vm.handleExceed,
                    "file-list": _vm.fileList,
                    headers: _vm.uploadHeader,
                    data: _vm.uploadData,
                  },
                },
                [
                  _c("i", { staticClass: "el-icon-upload" }),
                  _vm._v(" "),
                  _c("div", { staticClass: "el-upload__text" }, [
                    _vm._v("Drop file here or "),
                    _c("em", [_vm._v("click to upload")]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "el-upload__tip",
                      attrs: { slot: "tip" },
                      slot: "tip",
                    },
                    [_vm._v("jpg/png files with a size less than 500kb")]
                  ),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.submitExpense },
                },
                [_vm._v("Submit")]
              ),
              _vm._v(" "),
              _c(
                "router-link",
                {
                  staticClass: "el-button el-button--default",
                  attrs: { to: "/cash-expense" },
                },
                [_vm._v(_vm._s(_vm.$t("Order.Cancel")))]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }